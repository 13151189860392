import React, { useState} from 'react'
import { useAuth } from '../../context/authcontext';
import { useNavigate } from 'react-router-dom';
import useNuevaPeticion from '../Petiticiones/Nuevo/hooks/useNuevapeticion';
import llamada from '../../assets/iconos/llamar.svg';

const DetallesPendientes = () => {

  const navigate = useNavigate();
  const { 
    infoGuia,  
    SeleccionarGuiaPendiente,
    idGuia } = useAuth();
    const { HanlderCambioTelefonoPeticion } = useNuevaPeticion();
    const [prioritarios, setprioritarios] = useState(false);
    const [idGuiaEcommerce, setidGuiaEcommerce] = useState()
    // const informacionClienteGuia = () => {
    //   HanlderCambioTelefonoPeticion(res.telefono)
    // }

    const NavigateNuevaPeticion = (id) => {
      SeleccionarGuiaPendiente(id)
      navigate('/Peticiones/nuevo')
    }
console.log(infoGuia, "infoGuia")

const mostrarPrioritarios = (id) => {
  if (prioritarios === true) {
    setprioritarios(false)
  } else {
    setprioritarios(true)
  }
}

  return (
    <div style={{
        height:'500px',
        // background:'#fff',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        // boxShadow: 'rgb(106, 106, 106) -1px 2px 14px -3px'
    }}
    className='containerPendingPetitions'>
      <div className='containerWidget'>
      <p style={{paddingTop: '2px', textAlign:'center', fontWeight: 'bold', color:'#151B4E',fontSize: '18px'}}>Guías Ecommerce</p>
      </div>
      {
        infoGuia?.map(res => {
          return(
            <div style={{
              borderBottom:'solid #CCCCCC 1px', 
              width:'90%', 
              cursor:'pointer'}} 
              onClick={() => NavigateNuevaPeticion(res.idCliente)}>
              <p style={{fontSize:'15px', fontWeight: 'bold'}}>{res.idCliente} - {res.nombreCliente}</p>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', paddingBottom:'10px'}}>
              <div style={{width:'85%', display:'flex'}}>
              <img style={{height:'15px', paddingRight:'5px'}} src={llamada}/>
              <p style={{margin:'0px'}}>{res.telefono}</p>
              </div>
              <div style={{width:'15%'}}>
                <div className={res.envios.Pendientes.prioritarios === 0 || res.envios.Pendientes.prioritarios === "" || res.envios.Pendientes.prioritarios === null ? 'Total' : 'pendingTotal'}
                onMouseOut={() => mostrarPrioritarios(res.idCliente)}>
                <p style={{margin:'0px', color:'rgba(0, 0, 0, 1)', fontWeight:'bold'}}>{res.envios.Pendientes.total}</p>
                </div>
                {/* <div style={{width:'70px', height:'70px', background:'#fff', display: prioritarios === true ? 'block' : 'none'}}>
                  <p>{res.envios.Pendientes.prioritarios}</p>
                </div> */}
              </div>
              </div>
            </div>
          )
      })
    }
    </div>
  )
}

export default DetallesPendientes
