import React, { useEffect, useState } from 'react'
import moment from 'moment'
//COMPONENTES
import MapaMonitorNvo from '../components/Mapa/MapaMonitorNvo'
import WidgetRecolectores from '../components/Mapa/WidgetRecolectores'
import PeticionesPendientes from '../components/Mapa/PeticionesPendientes'
import DetallesREco from '../components/Mapa/DetallesREco'
import DetallesPendientes from '../components/Mapa/DetallesPendientes'
import DetallesPetiPendientes from '../components/Mapa/DetallesPetiPendientes'
//HOOKS
import useMapMonitorNvo from '../components/Mapa/hooks/useMapMonitorNvo'
import useSucursalesActivas from '../components/hooks/useSucursalesActivas'
import UsePeticiones from '../components/hooks/UsePeticiones'
import useGetZona from '../components/Mapa/hooks/useGetZona'
import UseDirections from '../components/Mapa/hooks/UseDirections'
//ESTILOS
import { MapaMonitorNuevoLayout } from '../theme/Layout'
//CONTEXT
import { useAuth } from '../context/authcontext'
//IMAGENES
import SucrusalIcon from '../assets/imagenes/sucursalnvo.webp'
import FlechaPeticiones from '../assets/iconos/flechaAbrir.svg'
import FlechaPeticionesOff from '../assets/iconos/flechaCerrar.svg'
import kangoo from '../assets/vehiculos/Kangoo.svg'
import leaf from '../assets/vehiculos/Leaf.svg'
import transit from '../assets/vehiculos/Transit.svg'
import kiri from '../assets/vehiculos/Kiri.svg'
import jac from '../assets/vehiculos/Jac.svg'
import PeticionAsig from '../assets/iconos/Peticiones/PeticionAsignada.svg'
import PeticionReco from '../assets/iconos/Peticiones/PeticionRecolectando.svg'
import PeticionDocu from '../assets/iconos/Peticiones/PeticionDocumentando.svg'
import PeticionPend from '../assets/iconos/Peticiones/PeticionPendiente.svg'
import guiasPendientes from '../assets/iconos/guiasPendientes.svg'
import peticionesPendientes from '../assets/iconos/peticionesPendientes.svg'





const MapaMonitoreoNvo = (props) => {

  const { RecolectoresMapa, socket, HandleEditReco, infoGuia } = useAuth()
  const { CargarSucursales, SucursalesData } = useSucursalesActivas()
  const { LoadZonasPorCiudad, DataZonaUsuario } = useGetZona()
  const { GetRoutes, Ruta, LimpiarRuta } = UseDirections()
  const { LoadPeticionesReco, PeticionesRecolectando, PeticionesDocumenando, PeticionesActivas, LoadPeticiones, PeticionesMapa } = UsePeticiones()
  const [FechAactual, setFechAactual] = useState(moment().format('YYYY-MM-DD'))
  const [RecolectorSelect, setRecolectorSelect] = useState()
  const [RutaEncurso, setRutaEncurso] = useState()
  const [Peticionespendientes, setPeticionespendientes] = useState([])
  const [AbrirPeticionesPendientes, setAbrirPeticionesPendientes] = useState(false)
  const [BotonAbrirPeticionesPendientes, setBotonAbrirPeticionesPendientes] = useState(false)
  const [RecolectorSelecionado, setRecolectorSelecionado] = useState()
  const [usarPeticionesMapa, setUsarPeticionesMapa] = useState(true); // true para usar PeticionesMapa, false para usar PeticionesActivas
  const [AbrirGuiasPendiente, setAbrirGuiasPendiente] = useState(false)

  // useEffect(() => {
  //   if (PeticionesMapa === undefined) {
  //     setTimeout(() => {
  //       LoadPeticiones(FechAactual, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
  //       LoadZonasPorCiudad()
  //       CargarSucursales()
  //     }, 2000);
  //   }else{
  //       LoadPeticiones(FechAactual, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
  //   }
  // }, [PeticionesMapa])

  // useEffect(() => {
  //   if (PeticionesActivas === undefined) {
  //     setTimeout(() => {
  //       LoadZonasPorCiudad()
  //       CargarSucursales()
  //     }, 2000);
  //   }else{
  //     LoadPeticionesReco(RecolectorSelecionado?.id,JSON.parse(localStorage.getItem("Ciudad")).idciudad)

  //   }
  // }, [PeticionesActivas])
  const cargarDatos = (idCiudad) => {
    LoadZonasPorCiudad();
    CargarSucursales();
    if (usarPeticionesMapa) {
      LoadPeticiones(FechAactual, idCiudad);
    } else {
      LoadPeticionesReco(RecolectorSelecionado?.id, idCiudad);
    }
  };

  useEffect(() => {
    const idCiudad = JSON.parse(localStorage.getItem("Ciudad"))?.idciudad;
    if (!idCiudad) return;
    const timeout = setTimeout(() => {
      if (PeticionesMapa === undefined) {
        cargarDatos(idCiudad);
      }
    }, 2000);
    return () => clearTimeout(timeout);
  }, [PeticionesMapa]);

  useEffect(() => {
    const idCiudad = JSON.parse(localStorage.getItem("Ciudad"))?.idciudad;
    if (!idCiudad) return;
    const timeout = setTimeout(() => {
      if (PeticionesActivas === undefined) {
        cargarDatos(idCiudad);
      }
    }, 2000);
    return () => clearTimeout(timeout);
  }, [PeticionesActivas]);
  //PETICIONES PENDIENTES
  const HandlerAbrirPeticionesPendientes = () => {
    if (AbrirPeticionesPendientes === true) {
      setAbrirPeticionesPendientes(false)
    } else {
      setAbrirPeticionesPendientes(true)
    }
  }
  useEffect(() => {
    if (PeticionesMapa != undefined) {
      const data = PeticionesMapa.filter(datos => datos.estatus === "P")
      setPeticionespendientes(data)
    }
  }, [PeticionesMapa])

  const showPendingPetitions = () => {
    if (Peticionespendientes === "" || PeticionesMapa === undefined || Peticionespendientes.length === 0 || AbrirGuiasPendiente === true) {
      return 'none'
    } else {
      return 'block'
    }
    // return (Peticionespendientes === "" || PeticionesMapa === undefined || Peticionespendientes.length === 0 || AbrirGuiasPendiente === true) ? 'none' : 'block';
  }
  
  //  useEffect(() => {
  //     if (PeticionesActivas != undefined) {
  //       const data = PeticionesActivas.filter(datos => datos.estatus === "P")
  //       setPeticionespendientes(data)
  //     }
  //   }, [PeticionesActivas])

  //SELECCIONAR RECOLECTOR
  const SeleccionarReco = (data) => {
    if (data.id === RecolectorSelecionado?.id) {
      setBotonAbrirPeticionesPendientes(false);
      setRecolectorSelecionado(undefined);
      LoadPeticiones(FechAactual, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    } else {
      setBotonAbrirPeticionesPendientes(true);
      setRecolectorSelecionado(data);
      LoadPeticionesReco(data.id, JSON.parse(localStorage.getItem("Ciudad")).idciudad);
    }
    toggleFuenteDatos();
  };
  // const SeleccionarReco = (data) =>{
  //   if(data.id === RecolectorSelecionado?.id){
  //     setBotonAbrirPeticionesPendientes(false)
  //     setRecolectorSelecionado(undefined)
  //     toggleFuenteDatos()
  //     // alert("peticiones mapa")
  //   }else{
  //     setBotonAbrirPeticionesPendientes(true)
  //     setRecolectorSelecionado(data)
  //     toggleFuenteDatos()
  //     // alert("peticiones activas")
  //     LoadPeticionesReco(data.id,JSON.parse(localStorage.getItem("Ciudad")).idciudad)

  //   }
  // }
  const SeleccionarRecomovil = () => {

  }
  //RUTA ECO
  const HandlerRutaEncurso = async (data) => {
    await setRutaEncurso(data)
  }
  useEffect(() => {
    if (RecolectorSelecionado) {

    } else {
      LimpiarRuta()
    }
  }, [RecolectorSelecionado])

  // useEffect(() => {

  //   LoadPeticionesReco(784,JSON.parse(localStorage.getItem("Ciudad")).idciudad)

  // }, [])


  const toggleFuenteDatos = () => {
    setUsarPeticionesMapa(prevState => !prevState); // Cambiar entre true/false
  };
  // const peticionesCondicional  = usarPeticionesMapa ? PeticionesMapa : PeticionesActivas;

  console.log(PeticionesMapa, "PeticionesMapa")
  console.log(PeticionesActivas, "PeticionesActivas")

  //SELECCIONAR PENDIENTES
  const SeleccionarGuias = () => {
    if (AbrirGuiasPendiente === true) {
      setAbrirGuiasPendiente(false)
    } else {
      setAbrirGuiasPendiente(true)
    }
  }

  return (
    <MapaMonitorNuevoLayout
      recoKANGOO={kangoo}
      recoLEAF={leaf}
      recoTRANSIT={transit}
      recoKIRI={kiri}
      recoJAC={jac}
      imgpetition={PeticionAsig}
      imgpetitionP={PeticionPend}
      imgpetitionR={PeticionReco}
      imgpetitionD={PeticionDocu}
      SucrusalIcon={SucrusalIcon}>
      <div className="containerMonitoreo" style={{ position: 'relative' }}>
        <div className='ContainerMapaCiudadReco'>
          <div className='containermonitormap'>
            <MapaMonitorNvo
              socket={socket}
              Recolectores={RecolectoresMapa}
              RecolectorSelecionado={RecolectorSelecionado}
              AccionCargarReco={SeleccionarReco}
              AccionEditarReco={HandleEditReco}
              RutaenCurso={RutaEncurso}
              RutaRecolector={Ruta}
              PeticionesMapa={usarPeticionesMapa ? PeticionesMapa : PeticionesActivas}
              DataZona={DataZonaUsuario}
              TipoMapa="City"
              Sucursales={SucursalesData}
              Zoom="10">
            </MapaMonitorNvo>
            <div className='containerdetails'>
            {/* WIDGET GUIAS PENDIENTES */}
            <div className='widgetGuiasPendientes' style={{
              position: 'absolute',
              top: 10,
              left: AbrirGuiasPendiente === true ? '47vh' : 5,
              // left: 5,
              cursor: 'pointer',
              transition: '1s',
              zIndex: '5',
              // display:AbrirPeticionesPendientes === false ? 'block' : 'none'
              display:'none'

            }}>
              <div className='widgetGuia' style={{
                background: '#007AEC'
              }}
                onClick={() => SeleccionarGuias()}>
                <img src={guiasPendientes}/>
              </div>
            </div>
            {/* WIDGET PETICIONES PENDIENTES */}
            <div className='widgetGuiasPendientes' style={{
              position: 'absolute',
              // top: AbrirPeticionesPendientes === true ? 10 : 90,
              top: AbrirPeticionesPendientes === true ? 10 : 10,
              left: AbrirPeticionesPendientes === true ? '47vh' : 5,
              // left: 5,
              cursor: 'pointer',
              transition: '1s',
              zIndex: '5',
              display: showPendingPetitions()
            }}>
              <div className='widgetGuia' style={{
                background: '#F29400'
              }}
                onClick={() => HandlerAbrirPeticionesPendientes()}>
                <img src={peticionesPendientes}/>
              </div>
            </div>
            </div>
            {/* WIDGET MOVIL */}
            <div className='containerdetailsmovil'>
            {/* WIDGET GUIAS PENDIENTES */}
            <div className='widgetGuiasPendientes' style={{
              position: 'absolute',
              top: 6,
              left: 5,
              // left: 5,
              cursor: 'pointer',
              transition: '1s',
              zIndex: '5',
              display:AbrirPeticionesPendientes === false ? 'block' : 'none'
            }}>
              <div className='widgetGuia' style={{
                background: '#007AEC'
              }}
                onClick={() => SeleccionarGuias()}>
                <img src={guiasPendientes}/>
              </div>
            </div>
            {/* WIDGET PETICIONES PENDIENTES */}
            <div className='widgetGuiasPendientes' style={{
              position: 'absolute',
              top: AbrirPeticionesPendientes === true ? 5 : 70,
              left:5,
              // left: 5,
              cursor: 'pointer',
              transition: '1s',
              zIndex: '5',
              display: showPendingPetitions()
            }}>
              <div className='widgetGuia' style={{
                background: '#F29400'
              }}
                onClick={() => HandlerAbrirPeticionesPendientes()}>
                <img src={peticionesPendientes}/>
              </div>
            </div>
            </div>
            {/* WIDGET RECOLECTOR */}
            <div className='widgetWebReco' style={{
              position: 'absolute',
              top: 10,
              right: RecolectorSelecionado ? '24%' : 5,
              cursor: 'pointer',
              transition: '1s',
              zIndex: '5'
            }}>
              {
                RecolectoresMapa?.map(resReco => {
                  if (resReco.cityId == JSON.parse(localStorage.getItem("Ciudad")).idciudad)
                    return (
                      <WidgetRecolectores
                        data={resReco}
                        RecolectorSelecionado={RecolectorSelecionado}
                        AccionCargarReco={SeleccionarReco}
                      ></WidgetRecolectores>
                    )
                })
              }
            </div>
            <div className='widgetMovilReco' style={{
              position: 'absolute',
              top: 5,
              right: 5,
              cursor: 'pointer',
              transition: '1s',
              zIndex: '5'
            }}>
              {
                RecolectoresMapa?.map(resReco => {
                  if (resReco.cityId == JSON.parse(localStorage.getItem("Ciudad")).idciudad)
                    return (
                      <WidgetRecolectores
                        data={resReco}
                        RecolectorSelecionado={RecolectorSelecionado}
                        AccionCargarReco={SeleccionarRecomovil}
                      ></WidgetRecolectores>
                    )
                })
              }
            </div>

            {/* <div style={Peticionespendientes.length > 0 ? AbrirPeticionesPendientes === false ? {bottom: '10px'} : dimension <= 1400 ? {top: '60px'} : {top:'160px'} :{display:'none'}} className='contenedorPeticionPendiente'> */}
            <div className={Peticionespendientes.length >= 0 ? AbrirPeticionesPendientes === false ? 'contenedorPeticionPendiente' : 'contenedorPeticionPendienteActiv' : ""}>
              <div style={Peticionespendientes === "" || PeticionesMapa === undefined || Peticionespendientes.length === 0 ? { display: 'none' } : { display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div style={BotonAbrirPeticionesPendientes === true ? { display: 'none' } : { display: 'block', zIndex: 7 }}>
                  {/* <div style={AbrirPeticionesPendientes === true ? { animation: 'none' } : { marginTop: '0px' }} onClick={() => HandlerAbrirPeticionesPendientes()} className='PeticionPendiente'>
                    <p>Ver peticiones pendientes</p>
                  </div> */}
                </div>

              </div>
            </div>
            {/* <div style={Peticionespendientes.length > 0 ? AbrirPeticionesPendientes === true ? { position: 'absolute', bottom: '10px', zIndex: 7 } : { height: '0vh', position: 'absolute', bottom: '4px', zIndex: 7 } : { display: 'none' }} className='TablaPeticionesPendientes'>
              <PeticionesPendientes
                PeticionesMapa={PeticionesMapa}
                CargaPeticiones={LoadPeticiones}
              ></PeticionesPendientes>
            </div> */}
          </div>
          <div className='containerdetails'>
          {/* CARD PENDIENTES */}
          <div style={AbrirGuiasPendiente === true ?
            { opacity: 1, width: '45vh', position: 'absolute', left: '2%', height: '100vh', top: 10, boxShadow: 'rgb(106, 106, 106) -1px 2px 14px -3px', background:'#F0F1F3' } :
            { opacity: 0, width: 0, position: 'absolute', left: 0, height: '100vh', top: 10, boxShadow: 'rgb(106, 106, 106) -1px 2px 14px -3px' }} className='ContainerDetallesPendientes'>
            <DetallesPendientes></DetallesPendientes>
          </div>
            {/* CARD PETICIONES PENDIENTES */}
            <div style={AbrirPeticionesPendientes === true ?
            { opacity: 1, width: '45vh', position: 'absolute', left: '2%', height: '100vh', top: 10, boxShadow: 'rgb(106, 106, 106) -1px 2px 14px -3px', background:'#F0F1F3' } :
            { opacity: 0, width: 0, position: 'absolute', left: 0, height: '100vh', top: 10, boxShadow: 'rgb(106, 106, 106) -1px 2px 14px -3px' }} className='ContainerDetallesPendientes'>
            <DetallesPetiPendientes PeticionesMapa={PeticionesMapa}></DetallesPetiPendientes>
          </div>
          </div>
          <div className='containerdetailsmovil'>
          {/* CARD PENDIENTES */}
          <div style={AbrirGuiasPendiente === true ?
            { opacity: 1, left: '2%', top: 40, background:'#F0F1F3', width: '96%' } :
            { opacity: 0, width: 0, left: 0, top: 40 }} className='ContainerDetallesPendientes'>
            <DetallesPendientes></DetallesPendientes>
          </div>
            {/* CARD PETICIONES PENDIENTES */}
            <div style={AbrirPeticionesPendientes === true ?
            { opacity: 1, left: '2%', top: 40, background:'#F0F1F3', width: '96%' } :
            { opacity: 0, width: 0, left: 0, top: 40 }} className='ContainerDetallesPendientes'>
            <DetallesPetiPendientes PeticionesMapa={PeticionesMapa}></DetallesPetiPendientes>
          </div>
          </div>
          {/* CARD RECOLECTOR */}
          <div style={RecolectorSelecionado ?
            { opacity: 1, position: 'absolute', right: '2%', height: '100vh', top: 10 } :
            { opacity: 0, width: 0, position: 'absolute', right: 0, height: '100vh', top: 10 }} className='ContainerDetallesRECO'>
            <DetallesREco
              RecolectorSelecionado={RecolectorSelecionado}
              PeticionesRecolectando={PeticionesRecolectando}
              PeticionesDocumenando={PeticionesDocumenando}
              PeticionesActivas={PeticionesActivas}
              AccionCargaRuta={GetRoutes}
              Ruta={Ruta}
              HandlerRutaEncurso={HandlerRutaEncurso}
            ></DetallesREco>
          </div>
        </div>
      </div>
    </MapaMonitorNuevoLayout>

  )
}

export default MapaMonitoreoNvo
